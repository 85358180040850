const faqPath = 'process.view-mobile-faq.content';
export const FAQ_ITEMS = [
    {
        question: `${faqPath}.questionZero`,
        answer: `${faqPath}.answerZero`,
    },
    {
        question: `${faqPath}.questionOne`,
        answer: `${faqPath}.answerOne`,
    },
    {
        question: `${faqPath}.questionTwo`,
        answer: `${faqPath}.answerTwo`,
    },
    {
        question: `${faqPath}.questionThree`,
        answer: `${faqPath}.answerThree`,
    },
    {
        question: `${faqPath}.questionFour`,
        answer: `${faqPath}.answerFour`,
    },
];
