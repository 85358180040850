import React, { useRef } from 'react';
import styled from 'styled-components';
import { AnimatedText } from 'components/animated-text';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { motion, useInView } from 'framer-motion';

const SHeader = styled(HeaderSecond)`
    font-weight: 300;
    text-align: center;
`;

const text = [
    {
        text: 'Working with a professional team that is proficient in every step of the product delivery process will be a part of your experience.',
        underlineWordIndexes: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
    },
];

const containerVariants = {
    visible: {
        transition: {
            staggerChildren: 0.025,
        },
    },
};

export const ProfessionalTeamSection = () => {
    const ref = useRef(null);
    const isInView = useInView(ref);

    return (
        <div ref={ref}>
            <Container>
                <motion.div
                    initial="hidden"
                    animate={isInView ? 'visible' : 'hidden'}
                    variants={containerVariants}
                >
                    <SHeader>
                        {text.map((item, index) => {
                            return <AnimatedText {...item} key={index} />;
                        })}
                    </SHeader>
                </motion.div>
            </Container>
        </div>
    );
};
