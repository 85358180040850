import React from 'react';
import styled from 'styled-components';
import { Container } from 'components/container';
import { RowWithTextImg } from 'modules/row-text-img';
import { arrayOf, bool, node, shape, string } from 'prop-types';

const SContainer = styled(Container)`
    display: flex;
    flex-direction: column;
    gap: 8.125rem;
`;

export const ContentRows = ({ columnsData, className, textColumnCss }) => {
    return (
        <SContainer className={className}>
            {columnsData.map((item, idx) => (
                <RowWithTextImg
                    key={idx}
                    {...item}
                    textColumnCss={textColumnCss}
                    isDescriptionFormattedPreviously
                />
            ))}
        </SContainer>
    );
};

ContentRows.propTypes = {
    className: string,
    columnsData: arrayOf(
        shape({
            title: string,
            descriptions: arrayOf(string),
            list: arrayOf(string),
            img: node.isRequired,
            alt: string,
            isReversed: bool,
        }),
    ).isRequired,
};

ContentRows.defaultProps = {
    className: '',
};
