import React from 'react';
import { useIntl } from 'react-intl';
import { ContentRows } from 'modules/content-rows';
import AgileSoftwareDevelopment from 'svgs/process/agile-software-development.svg';
import DataAnalyticsProcess from 'svgs/process/data-analytics-process.svg';

export const ProcessCirclesSection = () => {
    const { formatMessage } = useIntl();

    return (
        <ContentRows
            columnsData={[
                {
                    descriptions: [
                        formatMessage({
                            id: 'process.theSecret',
                        }),
                        formatMessage({
                            id: 'process.weAssistYou',
                        }),
                    ],
                    img: (
                        <AgileSoftwareDevelopment height="100%" width="100%" />
                    ),
                },
                {
                    descriptions: [
                        formatMessage({
                            id: 'process.dataAnalytics',
                        }),
                    ],
                    img: <DataAnalyticsProcess height="100%" width="100%" />,
                    isReversed: true,
                },
            ]}
        />
    );
};
