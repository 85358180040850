import React from 'react';
import styled from 'styled-components';
import { Plan } from 'views/how-we-work/process-plans-section/components/plan';
import { PROCESS_PLANS } from 'views/how-we-work/process-plans-section/constants';
import { Container } from 'components/container';
import { CONSTANTS } from 'constants/styles/constants';

const SWrapper = styled.div`
    background-color: var(--gray-color-90);
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 15rem;
    ${CONSTANTS.MEDIA.max1024`
        margin-top: 0;
        grid-template-columns: 1fr;
    `}
`;

export const ProcessPlansSection = () => {
    const plansToRender = PROCESS_PLANS.map((plan, index) => (
        <Plan key={plan.title} plan={plan} planIndex={index} />
    ));

    return (
        <Container>
            <SWrapper>{plansToRender}</SWrapper>
        </Container>
    );
};
