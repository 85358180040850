import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import { HeaderSecond } from 'components/header-second';
import { HeaderThird } from 'components/header-third';
import { StyledLi } from 'components/styled-li';
import { CONSTANTS } from 'constants/styles/constants';
import { arrayOf, bool, node, shape, string } from 'prop-types';

const SPlan = styled.div`
    padding: 3.75rem 1.5rem;
    display: flex;
    flex-direction: column;
    ${({ isCustom }) =>
        isCustom &&
        css`
            background-color: var(--secondary-color);
            color: var(--white-color);
            border-radius: 1.5rem;
            box-shadow: 0 0.875rem 2rem rgba(0, 0, 0, 0.1);
            margin: -4rem 0 3rem;
            ${CONSTANTS.MEDIA.max1024`
                margin: 0;
            `}
        `}
`;

const SHeaderThird = styled(HeaderThird)`
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
`;

const SCenteringWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 2rem;
`;

const SHeaderSecond = styled(HeaderSecond)`
    margin: 1rem 0;
`;

const SecondSubTitle = styled(SHeaderThird)`
    text-align: center;
`;

const SStyledLi = styled(StyledLi)`
    margin-left: 1.5rem;
    &:before {
        background-color: var(--black-color);
    }
    ${({ isCustom }) =>
        isCustom &&
        css`
            &:before {
                background-color: var(--white-color);
            }
        `}
`;

export const Plan = ({
    planIndex,
    plan: { icon, title, subTitle, description, secondSubTitle, list },
}) => {
    const isCustom = planIndex === 1;

    const listToRender = list.map((item) => (
        <SStyledLi key={item} isCustom={isCustom}>
            <FormattedMessage id={item} />
        </SStyledLi>
    ));

    return (
        <SPlan isCustom={isCustom}>
            <SCenteringWrapper>
                {icon}
                <SHeaderSecond>
                    <FormattedMessage id={title} />
                </SHeaderSecond>
                <SHeaderThird>
                    <FormattedMessage id={subTitle} />
                </SHeaderThird>
                <FormattedMessage id={description} />
            </SCenteringWrapper>
            <SecondSubTitle>
                <FormattedMessage id={secondSubTitle} />
            </SecondSubTitle>
            <ul>{listToRender}</ul>
        </SPlan>
    );
};

Plan.propTypes = {
    planIndex: bool.isRequired,
    plan: shape({
        icon: node,
        title: string,
        subTitle: string,
        description: string,
        secondSubTitle: string,
        list: arrayOf(string),
    }).isRequired,
};
