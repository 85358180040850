import React from 'react';
import MessageProgramming from 'svgs/icons/ic-message-programming-bold.svg';
import MonitorMobile from 'svgs/icons/ic-monitor-mobile-bolder.svg';
import ProgrammingArrows from 'svgs/icons/ic-programming-arrows.svg';
import UserMagnifier from 'svgs/icons/ic-user-magnifier.svg';
import UserTag from 'svgs/icons/ic-user-tag.svg';

export const PROCESS_DATA = [
    {
        icon: <UserTag />,
        title: 'process.productDiscovery',
        description: 'process.theExciting',
    },
    {
        icon: <UserMagnifier />,
        title: 'process.marketValidation',
        description: 'process.weNowHave',
    },
    {
        icon: <ProgrammingArrows />,
        title: 'process.delivery',
        description: 'process.weCanProceed',
    },
    {
        icon: <MessageProgramming />,
        title: 'process.maintenance',
        description: 'process.onceYourProduct',
    },
    {
        icon: <MonitorMobile />,
        title: 'process.handOff',
        description: 'process.ourConstantGoal',
    },
];
