import React from 'react';
import { ProcessCirclesSection } from 'views/how-we-work/process-circles-section';
import { ProcessMainSection } from 'views/how-we-work/process-main-section';
import { ProcessPlansSection } from 'views/how-we-work/process-plans-section';
import { ProcessWallsSection } from 'views/how-we-work/process-wall-section';
import { ProfessionalTeamSection } from 'views/how-we-work/professional-team-section';
import { FAQSection } from 'modules/faq-section';
import { RangeOfTechnologiesSection } from 'modules/range-of-technologies-section';
import { RequestSection } from 'modules/request-section';

import { FAQ_ITEMS } from './constants';

export const HowWeWorkView = () => {
    return (
        <>
            <ProcessMainSection />
            <ProfessionalTeamSection />
            <ProcessWallsSection />
            <ProcessCirclesSection />
            <RangeOfTechnologiesSection />
            <ProcessPlansSection />
            <RequestSection />
            <FAQSection
                title="process.view-mobile-faq.title"
                data={FAQ_ITEMS}
            />
        </>
    );
};
