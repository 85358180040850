import React from 'react';
import { HowWeWorkView } from 'views/how-we-work';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import messages from 'constants/translations/how-we-work.json';
import technologiesMessages from 'constants/translations/technologies/index.json';
import Layout from 'layouts/standard';
import { object } from 'prop-types';

const HowWeWorkPage = (props) => {
    const { location } = props;
    return (
        <Layout
            location={location}
            messages={{ ...messages, ...technologiesMessages }}
        >
            <HowWeWorkView />
        </Layout>
    );
};

HowWeWorkPage.propTypes = {
    location: object.isRequired,
};

export default HowWeWorkPage;

export const Head = () => <SEO tags={TAGS.SOFTWARE_DEVELOPMENT_PROCESS} />;
