import React from 'react';
import { motion } from 'framer-motion';
import { arrayOf, number, string } from 'prop-types';

const variants = {
    hidden: {
        y: '200%',
        transition: { duration: 0.5 },
    },
    visible: {
        y: 0,
        transition: { duration: 0.5 },
    },
};

export const AnimatedText = ({ text, underlineWordIndexes }) => {
    const words = text.split(' ');
    return (
        <>
            {words.map((word, index) => {
                const isUnderlined = underlineWordIndexes.includes(index);
                return (
                    <span
                        key={index}
                        style={{
                            overflow: 'hidden',
                            display: 'inline-block',
                        }}
                    >
                        <motion.span
                            style={{ display: 'inline-block' }}
                            className={isUnderlined ? 'underline' : undefined}
                            variants={variants}
                        >
                            {word}
                        </motion.span>
                        <span>{`\u00A0`}</span>
                    </span>
                );
            })}
        </>
    );
};

AnimatedText.propTypes = {
    text: string.isRequired,
    underlineWordIndexes: arrayOf(number).isRequired,
};
