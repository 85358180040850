import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { HeaderSecond } from 'components/header-second';
import { useTranslatedTitle } from 'hooks/use-translated-title';
import {
    FAQAccordionSection,
    FAQAccordionSectionProps,
} from 'modules/faq-accordion-section';
import { bool, string } from 'prop-types';

const SContainer = styled.div`
    content-visibility: auto;
    contain-intrinsic-size: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3.75rem;
`;

const Props = {
    title: string.isRequired,
    data: FAQAccordionSectionProps,
    parseRawAnswer: bool,
};

export const FAQSection = ({ title, data, parseRawAnswer }) => {
    const { kebabCaseId, handleCopyUrl } = useTranslatedTitle(title);

    return (
        <SContainer>
            <HeaderSecond id={kebabCaseId} handleCopyUrl={handleCopyUrl}>
                <FormattedMessage id={title} />
            </HeaderSecond>
            <FAQAccordionSection {...{ data, parseRawAnswer }} />
        </SContainer>
    );
};

FAQAccordionSection.propTypes = Props;
export { Props as FaqSectionProps };
