import React from 'react';
import Clock from 'svgs/icons/ic-clock-2.svg';
import ClockWallet from 'svgs/icons/ic-clock-wallet.svg';
import Wallet from 'svgs/icons/ic-wallet-bold.svg';

export const PROCESS_PLANS = [
    {
        icon: <Clock />,
        title: 'process.timeMaterial',
        subTitle: 'process.howItWorks',
        description: 'process.theContractedWorker',
        secondSubTitle: 'process.whenToChoose',
        list: [
            'process.youUnderstand',
            'process.youDesire',
            'process.thereIsnt',
            'process.youPrefer',
            'process.inYourIt',
        ],
    },
    {
        icon: <ClockWallet />,
        title: 'process.mixed',
        subTitle: 'process.howItWorks',
        description: 'process.theMixedModel',
        secondSubTitle: 'process.whenToChooseMixed',
        list: [
            'process.itGivesYou',
            'process.youCanAlso',
            'process.itWillBeEasier',
            'process.atTheEnd',
            'process.whenYouWant',
        ],
    },
    {
        icon: <Wallet />,
        title: 'process.fixedPrice',
        subTitle: 'process.howItWorks',
        description: 'process.isAModelThat',
        secondSubTitle: 'process.whenToChooseFixedPrice',
        list: [
            'process.youAreFullyAware',
            'process.youWantToBeCertain',
            'process.sinceYouAreConcerned',
            'process.theProjectsDeadline',
            'process.youAreAwareOfWhatYouWant',
        ],
    },
];
