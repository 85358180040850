import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { ArrowLink } from 'components/arrow-link';
import { HeaderThird } from 'components/header-third';
import { Paragraph } from 'components/paragraph';

const SExpertise = styled.div`
    display: flex;
    flex-direction: column;
    .icon-wrapper {
        transition: transform 0.3s ease-in-out;
    }
    &:hover {
        .icon-wrapper {
            transform: translateY(-0.5rem);
        }
    }
`;

const SHeaderThird = styled(HeaderThird)`
    margin: 0.5rem 0 1.25rem;
`;

const SIconWrapper = styled.div`
    width: fit-content;
    height: 2rem;
`;

const SArrowLink = styled(ArrowLink)`
    margin-top: 2rem;
`;

export const Expertise = ({
    expertise: { icon, title, description, to, label },
}) => {
    const { formatMessage } = useIntl();
    return (
        <SExpertise>
            <SIconWrapper className="icon-wrapper">{icon}</SIconWrapper>
            <SHeaderThird>
                <FormattedMessage id={title} />
            </SHeaderThird>
            <Paragraph>
                <FormattedMessage id={description} />
            </Paragraph>
            {to && (
                <SArrowLink
                    to={to}
                    label={label}
                    ariaLabel={`${formatMessage({
                        id: 'general.readMoreAbout',
                    })} ${formatMessage({
                        id: title,
                    })}`}
                    withText
                />
            )}
        </SExpertise>
    );
};
